/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable no-undef */
import { takeEvery, put, call, takeLatest, delay } from 'redux-saga/effects';
import { safeDeepGet } from 'iotera-base/utility/json';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError } from './actions';
//Include Both Helper File with needed methods
import { getInstance } from '../../../firebase/helper';
import {
  postSocialLogin,
} from '../../../helpers/fakebackend_helper';
const firebaseInstance = getInstance();
const MULTITENANCY = JSON.parse(localStorage.getItem('multitenancy'))
const APPLICATION_ID = localStorage.getItem('application_id');
function* loginUser({ payload: { user, history } }) {
  try {
  let email = user.email.toLowerCase();
  if(MULTITENANCY){
    var email_arr = email.split('@')
    email = email_arr[0]+"+"+APPLICATION_ID+'@'+email_arr[1]
  }
    const response = yield call(
      firebaseInstance.loginUser,
      email,
      user.password
    );
    var data = yield call(firebaseInstance.account.getRole, user.email.toLowerCase());
    if (data.code == -1) {
      email = email.charAt(0).toUpperCase() + email.slice(1);
      data = yield call(firebaseInstance.account.getRole, user.email.toLowerCase());
    }
    const role = safeDeepGet(data, ['body', 'role'], 'super_admin');
    localStorage.setItem('role', JSON.stringify(role));
    localStorage.setItem('email', user.email);
    var access = yield call(firebaseInstance.role.getAccess, role);
    const allBp = safeDeepGet(access, ['body', 'allBp'], 'false');
    const permision = safeDeepGet(access, ['body', 'permision'], 'false');
    const accessBP = safeDeepGet(access, ['body', 'businessPoint'], []);
    const accessNav = safeDeepGet(access, ['body', 'navigation'], []);
    if (allBp == 'true') {
      localStorage.setItem('allBp', true);
    } else {
      localStorage.setItem('allBp', false);
    }
    if (permision == 'true') {
      localStorage.setItem('permision', true);
    } else {
      localStorage.setItem('permision', false);
    }
    localStorage.setItem('accessBP', JSON.stringify(accessBP));
    localStorage.setItem('accessNav', JSON.stringify(accessNav));

    yield delay(3000)
    yield put(loginSuccess(response));
    history.push('/')
    location.reload();
  } catch (error) {
    yield put(apiError(error));
  }
}

// function* loginUser({ payload: { user, history } }) {
//   try {
//     let email = user.email.toLowerCase();
//     if(MULTITENANCY){
//       var email_arr = email.split('@')
//       email = email_arr[0]+"+"+APPLICATION_ID+'@'+email_arr[1]
//     }
//     var wsResult = yield call(
//       login,
//       email,
//       user.password,
//       APPLICATION_ID,
//       MULTITENANCY
//     );
//     var wsCode = safeGet(wsResult, 'code');
//     if (wsCode == Result.HTTP.OK) {
//       let response = safeGet(wsResult, ['body', 'body'], {});
//       response = yield call(
//         firebaseInstance.loginUser,
//         user.email,
//         user.password
//       );
//       const accessNav = safeDeepGet(wsResult, ['body', 'permision'], {});
//       localStorage.setItem('authUser', JSON.stringify(response));

//       const role = safeDeepGet(wsResult, ['body', 'role'], 'super_admin');
//       localStorage.setItem('role', JSON.stringify(role));
//       var access = yield call(firebaseInstance.role.getAccess, role);
//       const allBp = safeDeepGet(access, ['body', 'allBp'], 'false');
//       const permision = safeDeepGet(access, ['body', 'permision'], 'false');
//       const accessBP = safeDeepGet(access, ['body', 'businessPoint'], {});
//       if (allBp == 'true') {
//         localStorage.setItem('allBp', true);
//       } else {
//         localStorage.setItem('allBp', false);
//       }
//       if (permision == 'true') {
//         localStorage.setItem('permision', true);
//       } else {
//         localStorage.setItem('permision', false);
//       }
//       localStorage.setItem('accessBP', JSON.stringify(accessBP));
//       localStorage.setItem('accessNav', JSON.stringify(accessNav));
//       history.push('/dashboard');
//       location.reload();
//       yield put(loginSuccess(response));
//     } else {
//       yield put(
//         apiError(
//           'Maaf, Anda tidak memiliki izin. Silakan hubungi administrator atau pemilik aplikasi untuk mendapatkan akses yang sesuai.'
//         )
//       );
//     }
//   } catch (error) {
//     yield put(apiError(error));
//   }
// }
function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem('authUser');
    localStorage.removeItem('role');
    localStorage.removeItem('email');
    localStorage.removeItem('allBp');
    localStorage.removeItem('permision');
    localStorage.removeItem('accessBP');
    localStorage.removeItem('accessNav');
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(firebaseInstance.logout);
      yield put(logoutUserSuccess(response));
    }
    history.push('/login');
    location.reload();
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response = yield call(firebaseInstance.socialLoginUser, data, type);
      localStorage.setItem('authUser', JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem('authUser', JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push('/dashboard');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
